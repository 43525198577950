<template>
  <v-row
    align="center"
    class="fill-height"
  >
    <v-col>
      <v-card flat>
        <v-progress-circular
          class="d-block mx-auto my-auto"
          indeterminate
          size="64"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  mounted() {
    // Default route depends on permissions
    if (!this.$store.getters.authenticated) {
      return this.$router.replace("/login");
    } else if (this.$store.getters.getShowEventsTab) {
      return this.$router.replace("/myevents");
    } else if (this.$store.getters.getShowFopsTab) {
      return this.$router.replace("/myfops");
    } else {
      return this.$router.replace("/calendar");
    }
  },
};
</script>

<style>
</style>